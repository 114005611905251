import { useCallback, useMemo, useState } from "react";
import { batch } from "react-redux";
import type { Map } from "mapbox-gl";
import { useAppDispatch } from "@app/store/hooks";
import {
    appSetEnhancedLayers,
    appSetOSMLayersWithCategories,
} from "@app/store/userPreferences/userPreferences.actions";
import { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MapDACLayersPicker } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/dac/mapDACLayersPicker";
import { MapOSMLayersPicker } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/mapOSMLayersPicker";
import { useOsmCheckboxValues } from "@common/components/baseMap/customControls/mapLayers/mapLayersPickers/osm/useOsmCheckboxValues";
import {
    IMapLayersPickerDisplayConfig,
    MapLayers,
} from "@common/components/baseMap/mapLayers/mapLayers";
import {
    OSM_LAYERS,
    TMapLayersLocalState,
} from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { getNestedRoadTypes } from "@common/features/zonesManager/components/spotCounter/spotCounter.helpers";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import "./mapLayersPicker.less";

const DEFAULT_DISPLAY_CONFIG: IMapLayersPickerDisplayConfig = {
    showOsm: true,
    showDac: true,
};

const INITIAL_STATE = {
    mapLayersState: {
        enhancedLayers: [],
        osmLayersCategories: {},
    },
};

const DEFAULT_SELECTED_OSM_IDS = {
    [OSM_LAYERS.OSM_VEHICLE.code]: getNestedRoadTypes(OSM_LAYERS.OSM_VEHICLE.code).map(
        road => road.id,
    ),
};

export const MapLayersPicker = ({
    map,
    displayConfig = DEFAULT_DISPLAY_CONFIG,
    options,
    disabledOptions,
    mapLayersConfig,
}: {
    map: Map | null;
    options?: string[];
    disabledOptions?: string[];
    displayConfig?: IMapLayersPickerDisplayConfig;
    mapLayersConfig?: TControlConfig["mapLayersConfig"];
}) => {
    const [mapLayersState, setMapLayersState] = useState<TMapLayersLocalState>(
        INITIAL_STATE.mapLayersState,
    );

    const dispatch = useAppDispatch();

    const isNPAnalysis = arrayIncludes(
        [MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code, MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code],
        mapLayersConfig?.travelModeCode,
    );
    const { mapLayersLocalState, defaultSelectedIds } = useMemo(() => {
        return mapLayersConfig?.shouldUseLocalState
            ? {
                  defaultSelectedIds: null,
                  mapLayersLocalState: mapLayersState,
              }
            : {
                  defaultSelectedIds: DEFAULT_SELECTED_OSM_IDS,
                  mapLayersLocalState: null,
              };
    }, [mapLayersConfig, mapLayersState]);

    const updateMapLayersState = useCallback((value: Partial<TMapLayersLocalState>) => {
        setMapLayersState(prevState => ({ ...prevState, ...value }));
    }, []);

    const {
        values,
        onChange,
        resetToDefault: resetOsmLayersPickerToDefault,
    } = useOsmCheckboxValues({
        options,
        disabledOptions,
        isNPAnalysis,
        mapLayersLocalState,
        updateMapLayersState,
        defaultSelectedIds,
    });

    const handleReset = () => {
        if (mapLayersConfig?.shouldUseLocalState) {
            setMapLayersState(INITIAL_STATE.mapLayersState);
            resetOsmLayersPickerToDefault();
            return;
        }

        batch(() => {
            dispatch(appSetOSMLayersWithCategories(DEFAULT_SELECTED_OSM_IDS));
            dispatch(appSetEnhancedLayers([]));
        });

        resetOsmLayersPickerToDefault();
    };

    const _displayConfig = useMemo(() => {
        return { ...DEFAULT_DISPLAY_CONFIG, ...displayConfig };
    }, [displayConfig]);

    if (Object.values(_displayConfig).every(value => !value)) return null;

    return (
        <div className="stl-map-layers-picker">
            <label className="picker-label primary">Map Layers</label>
            {_displayConfig.showOsm && (
                <>
                    <label className="picker-label secondary" htmlFor="osm-layers-select">
                        OSM Layers
                    </label>
                    <MapOSMLayersPicker values={values} onChange={onChange} />
                </>
            )}
            {_displayConfig.showDac && (
                <MapDACLayersPicker
                    mapLayersLocalState={mapLayersLocalState}
                    updateMapLayersState={updateMapLayersState}
                />
            )}
            <div className="reset-button" role="none" onClick={handleReset}>
                Reset to Default
            </div>
            <MapLayers
                map={map}
                config={mapLayersConfig}
                displayConfig={_displayConfig}
                mapLayersLocalState={mapLayersLocalState}
            />
        </div>
    );
};
